import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  pageContext: {
    id: string
    title: string
    content: string
  }
}

const Post: React.FC<Props> = ({ pageContext }) => (
  <Layout>
    <SEO title={pageContext.title} />
    <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
    <Link to="/">Go to Home</Link>
  </Layout>
)

/*
export const pageQuery = graphql`
query BlogPostByID($id: String!) {
  wordpressPost(id: { eq: $id }) {
    id
    title
    content
  }
}`
*/
export default Post
